import React from 'react';

import './footer.style.css';

import phone from '../../../assets/phone.png';
import mail from '../../../assets/mail.png';
import payment from '../../../assets/payment.png';

class Footer extends React.Component {
    render() {
        return(
            <div className = "footer-container">
                <div className = "footer-texts">
                    <h1>Lorem ipsum sit dolor elit comsut yeit.</h1>
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Vel, iste quia beatae aut veniam fugiat iure velit perspiciatis quod officia, a assumenda obcaecati placeat, tenetur reiciendis iusto aperiam ipsum id?</p>
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Iste veritatis voluptates cumque, quia harum nulla consequuntur. Distinctio, perspiciatis eum molestias nihil architecto ex! Iure quisquam quaerat fugit neque, deserunt dolor?Lorem ipsum dolor sit, amet consectetur adipisicing elit. Sequi, consequatur laudantium, praesentium accusamus excepturi quae libero, omnis sit voluptate quis dignissimos perspiciatis molestiae facere. Tenetur iusto illum dolores deserunt quaerat!</p>
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Iste veritatis voluptates cumque, quia harum nulla consequuntur. Distinctio, perspiciatis eum molestias nihil architecto ex! Iure quisquam quaerat fugit neque, deserunt dolor?Lorem ipsum dolor sit, amet consectetur adipisicing elit. Sequi, consequatur laudantium, praesentium accusamus excepturi quae libero, omnis sit voluptate quis dignissimos perspiciatis molestiae facere. Tenetur iusto illum dolores deserunt quaerat!</p>
                </div>
                <div className = "footer-subscribe">
                    <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit.</p>
                    <div className = "subscribe-input">
                        <input placeholder = "Enter your email"/>
                        <button>Subscribe</button>
                    </div>
                </div>
                <div className = "footer-links">
                    <div className = "links-column">
                        <h1>HELLO PLUTO</h1>
                        <a>Track orders</a>
                        <a>About us</a>
                        <a>Contact</a>
                        <a>Careers</a>
                        <a>Sitemap</a>
                    </div>
                    <div className = "links-column">
                        <h1>POLICY & RULES</h1>
                        <a>Cancellations</a>
                        <a>Returns & refunds</a>
                        <a>Privacy policy</a>
                        <a>Terms & conditions</a>
                    </div>
                    <div className = "links-column">
                        <h1>SUPPLIERS</h1>
                        <a href= "http://localhost:3001/register">Become a supplier</a>
                        <a>Documents required</a>
                        <a>FAQs</a>
                    </div>
                    <div className = "links-column">
                        <h1>SUPPORT</h1>
                        <a>Chat with us</a>
                        <a>
                            <img src = {phone} alt = "phone-logo"/> 
                            +91 XXXX-XXX-XXX
                        </a>
                        <a>
                            <img src = {mail} alt = "mail-logo"/> 
                            info@hellopluto.com
                        </a>
                        <a>We accept</a>
                        <img src = {payment} alt = "payment" className = "payment-logo"/>
                    </div>
                </div>
                <div className = "footer-credits">
                    <div className = "line"></div>
                </div>
            </div>
        )
    }
}

export default Footer;