import React from 'react';

import './banner.style.css';

import bannerPhoto from '../../../../../assets/banner.jpg';

class Banner extends React.Component{
    render() {
        return(
            <div className = "banner-container">
                <img src = {bannerPhoto} alt = "banner"/>
            </div>
        )
    }
}

export default Banner;