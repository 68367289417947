import React from "react";

import { Link } from "react-router-dom";

import arrow from "../../../../../assets/down-arrow.png";
import search from "../../../../../assets/search.png";

import menu from "../../../../../assets/menu.png";

import "./categories.style.css";

class Categories extends React.Component {

  render() {
    var count = 0,
      count2 = 0;
    return (
      <div className="category-container">
        <div className="category-input">
          <div className="cat-dropdown">
            <div className="drop-btn">
              <p>All Categories</p>
              <img src={arrow} alt="arrow" />
            </div>

            <div className="category-dropdown">
              {this.props.categories.map((cat) => {
                return (
                  <a href={"./Category/" + cat.name}>
                    <p>{cat.name}</p>
                  </a>
                );
              })}
            </div>
          </div>
          <div className="cat-input">
            <div className="search-icon">
              <img src={search} alt="search-icon" />
            </div>
            <input placeholder="What are you looking for ?" />
          </div>
        </div>
        <div className="category-boxes">
          <div className="level-one">
            {this.props.categories.map((item) => {
              count++;
              if (count < 5) {
                if (item.active) {
                  return (
                    <a className="box" href={"./Category/" + item.name}>
                      <img src={item.image} alt={item.name} />
                      <p>{item.name}</p>
                    </a>
                  );
                }
                else{
                  return (
                    <a className="box" href={'/ComingSoon'}>
                      <img src={item.image} alt={item.name} />
                      <p>{item.name}</p>
                    </a>
                  );
                }
              }
            })}
          </div>
          <div className="level-two">
            {this.props.categories.map((item) => {
              count2++;
              if (count2 === 5) {
                if (item.active) {
                  return (
                    <a className="box" href={"./Category/" + item.name}>
                      <img src={item.image} alt={item.name} />
                      <p>{item.name}</p>
                    </a>
                  );
                }
                else {
                  return (
                    <a className="box" href={"/ComingSoon"}>
                      <img src={item.image} alt={item.name} />
                      <p>{item.name}</p>
                    </a>
                  );
                }
              }
            })}
            <div onClick={this.props.handleModalOpen} className="box">
              <img src={menu} alt="menu" />
              <p>More</p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Categories;
