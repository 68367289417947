import React from "react";
import { motion } from "framer-motion";
import "./signin.style.css";
import firebase from "firebase";
import Lottie from "lottie-react-web";
import animation from "./animation.json";
import animation2 from "./animation2.json";
import loading from "./loading.json";
import { Redirect } from "react-router-dom";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const pageVariants = {
  initial: {
    opacity: 0,
    x: "-100vw",
  },
  in: {
    opacity: 1,
    x: 0,
  },
  out: {
    opacity: 0,
    x: 0,
  },
};

const pageTransition = {
  type: "spring",
  damping: 20,
  stiffness: 100,
};

class Signin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      name: "",
      email: "",
      password: "",
    };
  }

  componentDidMount() {
    const signUpButton = document.getElementById("signUp");
    const signInButton = document.getElementById("signIn");
    const container = document.getElementById("container");

    signUpButton.addEventListener("click", () => {
      container.classList.add("right-panel-active");
    });

    signInButton.addEventListener("click", () => {
      container.classList.remove("right-panel-active");
    });
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        window.location.href = "/";
      }
    });
  }

  handleChange = (e) => {
    const { value, name } = e.target;
    this.setState({ [name]: value });
  };

  handleSubmitSignIn = () => {
    this.setState({
      loading: true,
    });

    if (this.state.email === "" || !this.state.email.includes("@")) {
      toast.info("Enter email correctly", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      });
      this.setState({
        loading: false,
      });
    } else if (this.state.password === "") {
      toast.error("Enter password");
      this.setState({
        loading: false,
      });
    } else {
      firebase
        .firestore()
        .collection("users")
        .where("email", "==", this.state.email)
        .get()
        .then((snap) => {
          if (snap.size > 0) {
            firebase
              .auth()
              .signInWithEmailAndPassword(this.state.email, this.state.password)
              .then(() => {
                toast.success("Logged In");
                this.setState({
                  loading: false,
                });
              })
              .catch((err) => {
                toast.error(err.message);
                this.setState({
                  loading: false,
                });
              });
          } else {
            toast.error("Please register!");
            this.setState({
              loading: false,
            });
          }
        });
    }
  };

  handleSubmitSignUp = () => {
    this.setState({
      loading: true,
    });

    if (this.state.name === "") {
      toast.info("Enter your name !", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      });
      this.setState({
        loading: false,
      });
    } else if (this.state.email === "" || !this.state.email.includes("@")) {
      toast.info("Enter email correctly !", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      });
      this.setState({
        loading: false,
      });
    } else if (this.state.password === "") {
      toast.error("Enter password !");
      this.setState({
        loading: false,
      });
    } else {
      this.props.signUp(this.state);
    }
  };

  render() {
    return (
      <motion.div
        initial="initial"
        animate="in"
        exit="out"
        variants={pageVariants}
        transition={pageTransition}
      >
        <ToastContainer />
        <div className="login-container">
          <div className="container" id="container">
            <div className="form-container sign-up-container">
              <form action="#">
                <h1>Create Account</h1>
                <div className="social-container">
                  <a href="/" style={{ cursor: "pointer" }} className="social">
                    <i className="fab fa-facebook-f"></i>
                  </a>
                  <a href="/" style={{ cursor: "pointer" }} className="social">
                    <i className="fab fa-google-plus-g"></i>
                  </a>
                </div>
                <span>or use your email for registration</span>
                <input
                  type="text"
                  placeholder="Name"
                  name="name"
                  onChange={this.handleChange}
                />
                <input
                  type="email"
                  placeholder="Email"
                  name="email"
                  onChange={this.handleChange}
                />
                <input
                  type="password"
                  placeholder="Password"
                  name="password"
                  onChange={this.handleChange}
                />
                {this.state.loading ? (
                  <Lottie
                    options={{ animationData: loading }}
                    width={50}
                    height={50}
                  />
                ) : (
                  <button type="button" onClick={this.handleSubmitSignUp}>
                    Register
                  </button>
                )}
              </form>
            </div>
            <div className="form-container sign-in-container">
              <form action="#">
                <h1>HIREPLUTO</h1>
                <div className="social-container">
                  <a href="/" className="social">
                    <i className="fab fa-facebook-f"></i>
                  </a>
                  <a href="/" className="social">
                    <i className="fab fa-google-plus-g"></i>
                  </a>
                </div>
                <span>or use your account</span>
                <input
                  type="email"
                  placeholder="Email"
                  name="email"
                  onChange={this.handleChange}
                />
                <input
                  type="password"
                  placeholder="Password"
                  name="password"
                  onChange={this.handleChange}
                />
                <a href="/">Forgot your password?</a>

                {this.state.loading ? (
                  <Lottie
                    options={{ animationData: loading }}
                    width={80}
                    height={80}
                  />
                ) : (
                  <button type="button" onClick={this.handleSubmitSignIn}>
                    Login
                  </button>
                )}
              </form>
            </div>
            <div className="overlay-container">
              <div className="overlay">
                <div className="overlay-panel overlay-left">
                  <h1>Welcome Back!</h1>
                  <div
                    className="lottie-pic"
                    style={{ width: "180px", height: "180px" }}
                  >
                    <Lottie
                      options={{
                        animationData: animation2,
                      }}
                    />
                  </div>
                  <p>
                    To keep connected with us please login with your personal
                    info
                  </p>
                  <button className="ghost" id="signIn">
                    Login
                  </button>
                </div>
                <div className="overlay-panel overlay-right">
                  <h1>Hello, Friend!</h1>
                  <div
                    className="lottie-pic"
                    style={{ width: "180px", height: "180px" }}
                  >
                    <Lottie
                      options={{
                        animationData: animation,
                      }}
                    />
                  </div>

                  <p>Enter your personal details and start journey with us</p>
                  <button className="ghost" id="signUp">
                    Register
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </motion.div>
    );
  }
}

export default Signin;
