import React from "react";
import "./card.styles.css";
import truck from './truck.svg';

const Card = (props) => {

  var toggle = false;
  return (
    <div className="card-container">
      <div className="image-container">
        <a href={"/Category/" + props.item.category + "/" + props.item.subCategory + "/" + props.item.title}>
          <img src={props.item.cover} alt="prod-img" />
        </a>
      </div>

      <div className="title">
        <a href={"/Category/" + props.item.category + "/" + props.item.subCategory + "/" + props.item.title}><h1>{props.item.title}</h1></a>
        {
          props.item.isWished
            ?
            <i class="fas fa-heart red" onClick={() => props.removeFromWishlist(props.item)}></i>
            :
            <i class="far fa-heart" onClick={() => props.addToWishlist(props.item)}></i>
        }

      </div>

      <div className="info">
        <p>
          &#8377;{props.item.rent}
          <span>/month</span>
        </p>

        <div className="delivery">
          {/* {props.item.day > 1 ? (
            <p className="day">{props.item.day} days</p>
          ) : (
            <p className="day">{props.item.day} day</p>
          )} */}
          <img src={truck} alt="truck" />
          <p className="day">{props.item.day} 14 days</p>
        </div>
      </div>
    </div>
  );
};

export default Card;
