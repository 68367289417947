import React from "react";

import "./navbar.style.css";
import itemimg from "./bed.jpg";
import logo from "../../../assets/header.png";
import cart from "./cart.svg";
import firebase from "firebase";
import empty from "./17990-empty-cart.json";
import Lottie from "lottie-react-web";

class Navbar extends React.Component {
  constructor() {
    super();
    this.state = {
      currentUser: [],
    };
  }
  componentDidMount() {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        console.log(user.email);
        console.log(firebase.auth().currentUser.email);
        firebase
          .firestore()
          .collection("users")
          .where("email", "==", user.email)
          .onSnapshot((snap) => {
            console.log(snap.size);
            snap.docChanges().forEach((change) => {
              this.setState(
                {
                  currentUser: change.doc.data(),
                },
                () => {
                  console.log(this.state.currentUser);
                }
              );
            });
          });
      } else {
        this.setState({
          currentUser: [],
        });
      }
    });
    // var modal = document.querySelector(".modal");
    // var trigger = document.querySelector(".trigger");
    // var closeButton = document.querySelector(".close-button");

    // function toggleModal() {
    //   modal.classList.toggle("show-modal");
    // }

    // function windowOnClick(event) {
    //   if (event.target === modal) {
    //     toggleModal();
    //   }
    // }

    // trigger.addEventListener("click", toggleModal);
    // closeButton.addEventListener("click", toggleModal);
    // window.addEventListener("click", windowOnClick);
  }

  handleSignOut = () => {
    firebase
      .auth()
      .signOut()
      .then(() => {
        console.log("user signed out");
        window.location.href = "/signin";
      });
  };
  render() {
    return (
      <React.Fragment>
        <div className="nav">
          <div className="nav-container">
            <div className="nav-logo">
              <a href="/">
                <img src={logo} alt="logo" />
              </a>
              {/* <div className="nav-select-city">
                <div className="nav-city-btn">
                  <img className="pin-logo" src={pin} alt="pin" />
                  <p>Select City</p>
                  <button className="trigger" onClick={this.handleOpenModal}>
                    <i class="fas fa-chevron-down"></i>
                  </button>
                </div>
              </div> */}
            </div>
            <div className="nav-menu">
              <div className="nav-menu-list">
                <a href="/">
                  <p>ABOUT US</p>
                </a>
                <a href="/">
                  <p>CONTACT US</p>
                </a>
                <a href="/">
                  <p>FAQ</p>
                </a>
              </div>
            </div>
            <div className="nav-extras">
              {this.state.currentUser.name ? (
                <>
                  <div className="user-profile">
                    <p>Hello, {this.state.currentUser.name}</p>
                    <i class="fas fa-chevron-down"></i>
                    <div className="profile-menu-active">
                      <div className="profile-menu-list">
                        <a className="menu" href="/Dashboard/Profile">
                          <i className="far fa-user"></i>
                          <p>Profile</p>
                        </a>
                        <a className="menu" href="/Dashboard/Orders">
                          <i className="far fa-list-alt"></i>
                          <p>Orders</p>
                        </a>
                        <a className="menu" href="/Dashboard/Wishlist">
                          <i className="far fa-heart"></i>
                          <p>Wishlist</p>
                        </a>
                      </div>
                    </div>
                    <div className="menu-pointer"></div>
                  </div>

                  <div className="cart-logo">
                    <a href="/Cart">
                      <img src={cart} alt="cart-logo" />
                      {/* <p>Cart</p> */}
                    </a>
                    <div className="cart-menu-active">
                      <div className="cart-list">
                        {this.state.currentUser.cart.map((item) => {
                          return (
                            <a
                              href={
                                "/Category/" +
                                item.category +
                                "/" +
                                item.subCategory +
                                "/" +
                                item.title
                              }
                              className="cart-item"
                            >
                              <div className="left">
                                <img src={item.cover} />
                              </div>
                              <div className="right">
                                <p className="title">{item.title}</p>
                                <div className="info">
                                  <div className="info-item">
                                    <p className="info-title">Tenure</p>
                                    <p className="info-value">
                                      {item.month} months
                                    </p>
                                  </div>
                                  <div className="info-item">
                                    <p className="info-title">Rental</p>
                                    <p className="info-value">
                                      &#8377;{item.rent}/m
                                    </p>
                                  </div>
                                  <div className="info-item">
                                    <p className="info-title">Deposit</p>
                                    <p className="info-value">
                                      &#8377;{item.deposit}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </a>
                          );
                        })}
                        {this.state.currentUser.cart.length === 0 ? (
                          <div
                            className="cart-item"
                            style={{
                              flexDirection: "column",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <Lottie
                              options={{ animationData: empty }}
                              width={100}
                              height={100}
                            />
                            <p style={{ color: "#313131", fontSize: "12px" }}>
                              No items present in cart
                            </p>
                          </div>
                        ) : null}
                      </div>
                      <a href="/Cart" className="cart-button">
                        Go to cart
                      </a>
                    </div>
                    <div className="menu-pointer"></div>
                  </div>
                </>
              ) : null}
              <div className="login-btn">
                {this.state.currentUser.name ? (
                  <button onClick={this.handleSignOut}>
                    <a>Logout</a>
                  </button>
                ) : (
                  <a href="/signin">
                    <button>Login / Register</button>
                  </a>
                )}
              </div>
            </div>
          </div>
        </div>

        {/* <div className="modal">
          <div class="modal-content">
            <div className="modal-header">
              <p>SELECT YOUR CITY</p>
              <div class="close-button">
                <i class="far fa-times-circle"></i>
              </div>
            </div>
            <div className="modal-body"></div>
          </div>
        </div> */}
      </React.Fragment>
    );
  }
}

export default Navbar;
