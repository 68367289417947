import React from "react";
import "./homepage.style.css";
import { motion } from "framer-motion";
import Banner from "./sections/banner/banner";
import Categories from "./sections/categories/categories";
import firebase from "firebase";
import Lottie from "lottie-react-web";
import loading from "../../../assets/loading.json";

const pageVariants = {
  initial: {
    opacity: 0,
    x: "-100vw",
  },
  in: {
    opacity: 1,
    x: 0,
  },
  out: {
    opacity: 0,
    x: 0,
  },
};

const pageTransition = {
  type: "spring",
  damping: 20,
  stiffness: 100,
};

class Homepage extends React.Component {
  constructor() {
    super();
    this.state = {
      categories: [],
      showCat: false,
      loading: true,
      modal: "modal-cat",
      home: "home",
    };
  }
  componentDidMount() {
    firebase
      .firestore()
      .collection("settings")
      .onSnapshot((snap) => {
        snap.docChanges().forEach((change) => {
          this.setState({
            categories: change.doc.data().categories,
            loading: false,
          });
        });
      });
  }

  handleCategoryOpen = (item) => {
    this.setState({
      showCat: true,
      cat: item,
      modal: "modal-cat",
    });
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0;
  };
  handleCategoryClose = () => {
    this.setState({
      showCat: false,
    });
  };
  handleModalOpen = () => {
    console.log("OPEN MODAL MORE");
    this.setState({
      modal: "modal-cat-active",
    });
  };
  handleModalClose = () => {
    this.setState({
      modal: "modal-cat",
    });
  };
  render() {
    return (
      <>
        {this.state.loading ? (
          <div
            style={{
              width: "100%",
              height: "100vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Lottie
              options={{ animationData: loading }}
              width={150}
              height={150}
            />
          </div>
        ) : (
          <motion.div
            initial="initial"
            animate="in"
            exit="out"
            variants={pageVariants}
            transition={pageTransition}
            className="homepage-container"
          >
            <Banner />
            <Categories
              handleModalOpen={this.handleModalOpen}
              handleCategoryOpen={(item) => this.handleCategoryOpen(item)}
              categories={this.state.categories}
            />
            <div className={this.state.modal}>
              <div class="modal-cat-content">
                <div className="modal-cat-header">
                  <p>SELECT A CATEGORY</p>
                  <div
                    onClick={this.handleModalClose}
                    class="modal-cat-close-button"
                  >
                    <i class="far fa-times-circle"></i>
                  </div>
                </div>
                <div className="modal-cat-body">
                  <h1>ALL CATEGORIES</h1>
                  <div className="list">
                    {this.state.categories.map((item) => {
                      return (
                        <div
                          className="box"
                          onClick={() => this.handleCategoryOpen(item)}
                        >
                          <img src={item.image} alt={item.name} />
                          <p>{item.name}</p>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </motion.div>
        )}
      </>
    );
  }
}

export default Homepage;
